*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
    background-color: #63248F;
  }
  
  .container{
    width: 100vw;
    min-height: 100vh;
    background-color: black;
  }