.body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 100px; */
    flex-direction: column;
}

.body-title {
    font-size: calc(20px + 1vw);
    font-weight: 700;
    /* margin-bottom: 30px; */
    /* margin-top: 30px; */
}


@media (max-width: 1024px) {
    .image, .images {
        max-width: 100%;
        /* margin-bottom: 10px; */
    }

}


@media screen and (max-width: 480px) {
    .body-title {
        margin-top: 50px;
    }
}


.social-icons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;              
    margin-top: 20px;
    margin-bottom: 20px;
}
